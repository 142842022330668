var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled && _vm.isOld,
                        expression: "editable&&!disabled&&isOld",
                      },
                    ],
                    attrs: { label: "삭제", icon: "delete" },
                    on: { btnClicked: _vm.deleteLawCheck },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled,
                        expression: "editable && !disabled",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.lawCheck,
                      mappingType: _vm.mappingType,
                      label: "저장",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveLawCheck,
                      btnCallback: _vm.saveLawCheckCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.requestBtnEditable,
                        expression: "requestBtnEditable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isRequest,
                      url: _vm.completeUrl,
                      param: _vm.lawCheck,
                      mappingType: "PUT",
                      label: "검토요청",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.requestLawCheck,
                      btnCallback: _vm.requestLawCheckCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.completeBtnEditable,
                        expression: "completeBtnEditable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.completeUrl,
                      param: _vm.lawCheck,
                      mappingType: "PUT",
                      label: "완료",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completeLawCheck,
                      btnCallback: _vm.completeLawCheckCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    data: _vm.lawCheck,
                    deptValue: "managerDeptCd",
                    type: "dept_user",
                    label: "담당자",
                    name: "managerId",
                  },
                  model: {
                    value: _vm.lawCheck.managerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "managerId", $$v)
                    },
                    expression: "lawCheck.managerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-dept-multi", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    parentCheckDepts: _vm.lawCheck.acceptDeptCds,
                    label: "적용부서",
                    name: "acceptDeptCds",
                  },
                  model: {
                    value: _vm.lawCheck.acceptDeptCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "acceptDeptCds", $$v)
                    },
                    expression: "lawCheck.acceptDeptCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "해당시설",
                    name: "applicableFacility",
                  },
                  model: {
                    value: _vm.lawCheck.applicableFacility,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "applicableFacility", $$v)
                    },
                    expression: "lawCheck.applicableFacility",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "현행 법규내용 요약",
                    name: "currentLawContents",
                  },
                  model: {
                    value: _vm.lawCheck.currentLawContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "currentLawContents", $$v)
                    },
                    expression: "lawCheck.currentLawContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "개정 법규내용 요약",
                    name: "reLawContents",
                  },
                  model: {
                    value: _vm.lawCheck.reLawContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "reLawContents", $$v)
                    },
                    expression: "lawCheck.reLawContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "검토요청내용",
                    name: "reviewRequestContents",
                  },
                  model: {
                    value: _vm.lawCheck.reviewRequestContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "reviewRequestContents", $$v)
                    },
                    expression: "lawCheck.reviewRequestContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "비고",
                    name: "remark",
                  },
                  model: {
                    value: _vm.lawCheck.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawCheck, "remark", $$v)
                    },
                    expression: "lawCheck.remark",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm q-mt-sm q-mb-sm",
          attrs: { noHeader: "" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          readonly: true,
                          editable: _vm.editable,
                          appendIcon: [
                            {
                              name: "search",
                              click: true,
                              callbackName: "searchLaw",
                            },
                          ],
                          label: "법령명(약칭)",
                          name: "lawNameKor",
                        },
                        on: { searchLaw: _vm.searchLaw },
                        model: {
                          value: _vm.lawCheck.lawNameKor,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawCheck, "lawNameKor", $$v)
                          },
                          expression: "lawCheck.lawNameKor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "공포일자",
                          name: "promulgationDate",
                        },
                        model: {
                          value: _vm.lawCheck.promulgationDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawCheck, "promulgationDate", $$v)
                          },
                          expression: "lawCheck.promulgationDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "제개정",
                          name: "revisiontypename",
                        },
                        model: {
                          value: _vm.lawCheck.revisiontypename,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawCheck, "revisiontypename", $$v)
                          },
                          expression: "lawCheck.revisiontypename",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "소관부처",
                          name: "competentMinistriesName",
                        },
                        model: {
                          value: _vm.lawCheck.competentMinistriesName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.lawCheck,
                              "competentMinistriesName",
                              $$v
                            )
                          },
                          expression: "lawCheck.competentMinistriesName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "법령구분",
                          name: "lawClassName",
                        },
                        model: {
                          value: _vm.lawCheck.lawClassName,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawCheck, "lawClassName", $$v)
                          },
                          expression: "lawCheck.lawClassName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "시행일자",
                          name: "enforcementDate",
                        },
                        model: {
                          value: _vm.lawCheck.enforcementDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawCheck, "enforcementDate", $$v)
                          },
                          expression: "lawCheck.enforcementDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _vm.lawCheck.mdmLawId
                        ? _c(
                            "q-chip",
                            {
                              staticClass: "cursor-pointer",
                              attrs: {
                                outline: "",
                                square: "",
                                color: "teal",
                                "text-color": "white",
                                icon: "open_in_new",
                                clickable: "",
                              },
                              on: { click: _vm.fileDown },
                            },
                            [_vm._v(_vm._s(_vm.$language("법령안")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "검토자 목록",
            columns: _vm.userTableColumns,
            data: _vm.lawCheck.checkUsers,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "mdmLawCheckUserId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.lawCheck.checkUsers.length > 0
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.deleteChecker },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addChecker },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }